import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Graphs from './Graphs'; // Import the Graphs component
import './CreatorPage.css';

const CreatorPage = () => {
    const { creatorID } = useParams(); // Use creatorID from URL parameters

    const [creator, setCreator] = useState(null);
    const [amount, setAmount] = useState(0);
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault(); // Prevent the default form submit behavior
    
        // Initialization of waitlistData should happen before it's logged or used
        const waitlistData = {
            creatorId: creatorID,
            amount: parseInt(amount, 10),
            fullName: fullName,
            email: email,
            price: parseFloat(price)
        };
    
        // Now that waitlistData is initialized, you can log it
        console.log(waitlistData);
    
        try {
            const response = await fetch('https://nkatbqe4c9.execute-api.us-east-1.amazonaws.com/dev/waitlist', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(waitlistData)
            });
    
            if (response.ok) {
                console.log('Waitlist entry successful');
                // Optionally reset form or give user feedback
            } else {
                console.error('Failed to submit waitlist entry');
            }
        } catch (error) {
            console.error('Error submitting waitlist:', error);
        }
    };
    

    // Example mapping from anonymous ID to static details
    const creatorDetails = {
        "yt1": {
            channelName: "EthanKingyt",
            imageUrl: "/CreatorBoxImage1.jpg",
            category: "Lifestyle",
            rpm: "$3.5",
            price: "10",
            percentage: "0.01"
        },
        "yt2": {
            channelName: "JacksonBrooksby",
            imageUrl: "/CreatorBoxImage2.jpg",
            category: "Music",
            rpm: "$4.2",
            price: "8",
            percentage: "0.01"
        },
        "yt3": {
            channelName: "itssamueltan",
            imageUrl: "/CreatorBoxImage3.jpg",
            category: "Tech",
            rpm: "$5.1",
            price: "12",
            percentage: "0.01"
        },
        "yt4": {
            channelName: "OliverCowlishaw",
            imageUrl: "/CreatorBoxImage4.jpg",
            category: "Self-improvement",
            rpm: "$4.1",
            price: "15",
            percentage: "0.01"
        }
    };

    useEffect(() => {
        if (!creatorID || !creatorDetails[creatorID]) {
            console.error("Invalid creator ID provided.");
            return;
        }

        const staticData = creatorDetails[creatorID];
        const channelName = staticData.channelName;

        fetch('http://54.160.244.203:5000/stats')
            .then(response => response.json())
            .then(data => {
                const fetchedData = data.find(item => item.channel_name === channelName);
                if (fetchedData) {
                    setCreator({
                        ...staticData,
                        subscribers: fetchedData.subscribers ? parseInt(fetchedData.subscribers).toLocaleString() : "N/A",
                        views: fetchedData.views ? parseInt(fetchedData.views).toLocaleString() : "N/A",
                        videos: fetchedData.videos || "N/A",
                        active_for: fetchedData.active_for || "N/A"
                    });
                } else {
                    console.log("No matching creator found in fetched data.");
                    setCreator(null);
                }
            })
            .catch(error => {
                console.error('Error fetching creator data:', error);
                setCreator(null);
            });
    }, [creatorID]);

    if (!creator) {
        return <div>No creator data available. Please ensure a valid identifier is provided.</div>;
    }

    const { imageUrl, channelName, category, rpm, price, percentage, subscribers, views, videos, active_for } = creator;

    // Compute investment details text based on the amount
    const investmentDetails = amount > 0 ? 
        `Waitlist to buy ${(amount * parseFloat(percentage)).toFixed(2)}% for $${formatDollars(amount * price)}` : 
        "Choose Amount";

    // Function to format dollar values
    function formatDollars(num) {
        return num.toFixed(2).replace(/\.00$/, '');
    }

    return (
        <div className="creator-page">
            <div className="creator-content">
                <img src={imageUrl} alt={`${channelName} logo`} className="creator-image" />
                <div className="creator-grid">
                    <div className="info-box">Category: {category}</div>
                    <div className="info-box">RPM: {rpm}</div>
                    <div className="info-box">Subscribers: {subscribers}</div>
                    <div className="info-box">Views: {views}</div>
                    <div className="info-box">Videos: {videos}</div>
                    <div className="info-box">Active for: {active_for} years</div>
                    <div className="info-box investment-box">Investment: ${price} for {percentage}%</div>
                </div>
                <div className="join-waitlist">
                <div className="input-group">
                    <button className="input-button decrement" onClick={() => setAmount(prev => Math.max(0, prev - 1))}>-</button>
                    <input 
                        type="number" 
                        className="number-input" 
                        value={amount > 0 ? amount : ''} // Display an empty string if amount is 0
                        onChange={e => setAmount(Math.max(0, parseInt(e.target.value) || 0))} 
                        placeholder='Choose Amount' 
                    />
                    <button className="input-button increment" onClick={() => setAmount(prev => prev + 1)}>+</button>
                </div>
                <label className="form-label">
                    <input type="text" value={fullName} onChange={e => setFullName(e.target.value)} placeholder="Full Name" />
                </label>
                <label className="form-label">
                    <input type="email" value={email} onChange={e => setEmail(e.target.value)} placeholder="Email" />
                </label>
                <div className="investment-details">
                    {investmentDetails}
                </div>
                <button type="submit" className="waitlist-btn" onClick={handleSubmit}>Join Waitlist</button>
            </div>

            </div>
            <Graphs channelName={channelName} />
        </div>
    );
};

export default CreatorPage;
