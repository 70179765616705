import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale, Filler } from 'chart.js';
import 'chartjs-adapter-date-fns';
import { format } from 'date-fns';
import './ViewsPerVideoGraph.css';


// Register necessary Chart.js components along with the Filler plugin
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale, Filler);

const Graphs = ({ channelName }) => {
    const [videoData, setVideoData] = useState([]);

    useEffect(() => {
        fetch(`https://lzln18r7gj.execute-api.us-east-1.amazonaws.com/dev/fetch-graph-data`)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                const filteredData = data.filter(video => video.channel_name === channelName);
                const groupedData = {};
                filteredData.forEach(video => {
                    const monthYear = format(new Date(video.date), 'yyyy-MM');
                    if (!groupedData[monthYear]) {
                        groupedData[monthYear] = { views: 0, count: 0 };
                    }
                    groupedData[monthYear].views += parseInt(video.views, 10);
                    groupedData[monthYear].count += 1;
                });
                const formattedData = Object.keys(groupedData).map(monthYear => {
                    return {
                        date: new Date(monthYear + '-01'),
                        views: groupedData[monthYear].views / groupedData[monthYear].count
                    };
                });
                setVideoData(formattedData.sort((a, b) => a.date - b.date));
            })
            .catch(error => {
                console.error('Error fetching video data:', error);
            });
    }, [channelName]);

    const data = {
        datasets: [{
            label: 'Views per Video',
            data: videoData.map(video => ({
                x: video.date,
                y: video.views
            })),
            borderColor: '#eb455f',
            backgroundColor: function(context) {
                const chart = context.chart;
                const {ctx, chartArea} = chart;

                if (!chartArea) {
                    // This case happens on initial chart load
                    return;
                }
                const gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
                gradient.addColorStop(0, 'rgba(235, 69, 95, 0)');
                gradient.addColorStop(0.3, 'rgba(235, 69, 95, 0.5)');
                return gradient;
            },
            fill: true,
            tension: 0.3
        }]
    };


const options = {
    scales: {
        x: {
            type: 'time',
            time: {
                unit: 'month',
                displayFormats: {
                    month: 'MMM yyyy'
                },
                tooltipFormat: 'MMMM yyyy'
            },
            ticks: {
                padding: 0,
                color: '#86878f', // Color of the axis tick labels
                // Format the date correctly in the callback
                callback: function(value, index, ticks) {
                    // Convert the timestamp to a Date object
                    const date = new Date(ticks[index].value);
                    // Display the label only every four months
                    if (index % 6 === 0) {
                        return format(date, 'MMM yy'); // Format date as 'MMM yyyy'
                    }
                    return '';
                }
            },
            grid: {
                color: '#293045' // Color of the grid lines
            }
        },
        y: {
            type: 'linear',
            beginAtZero: true,
            ticks: {
                padding: 0,
                color: '#86878f' // Color of the axis tick labels
            },
            grid: {
                color: '#293045' // Color of the grid lines
            }
        }
    },
        responsive: true,
        plugins: {
            legend: {
                display: false // Hide the legend
            },
            tooltip: {
                enabled: true,
                mode: 'index',
                intersect: false,
                displayColors: false  // Disables the color box next to the tooltip text

            }
        },
    };

    return (
        <div className="chart-container" style={{backgroundColor: "#27293c", color: "#707179", padding: "20px", borderRadius: "8px"}}>
        <h2 className="chart-title">Views per Video</h2>
            <Line data={data} options={options} />
        </div>
    );
};

export default Graphs;
