import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import CreatorProfile from './CreatorProfileBox';
import './Marketplace.css';

const Marketplace = () => {
    const [creators, setCreators] = useState([
        {
            creatorID: "yt1", 
            imageUrl: "/CreatorBoxImage1.jpg",
            channelName: "EthanKingyt",
            category: "Lifestyle",
            rpm: "$3.5",
            price: "10",
            percentage: "0.01"
        },
        {
            creatorID: "yt2", 
            imageUrl: "/CreatorBoxImage2.jpg",
            channelName: "JacksonBrooksby",
            category: "Music",
            rpm: "$4.2",
            price: "8",
            percentage: "0.01"
        },
        {
            creatorID: "yt3", 
            imageUrl: "/CreatorBoxImage3.jpg",
            channelName: "itssamueltan",
            category: "Tech",
            rpm: "$5.1",
            price: "12",
            percentage: "0.01"
        },
        {
            creatorID: "yt4", 
            imageUrl: "/CreatorBoxImage4.jpg",
            channelName: "OliverCowlishaw",
            category: "Self-improvement",
            rpm: "$4.1",
            price: "15",
            percentage: "0.01"
        }
    ]);

    useEffect(() => {
        fetch('https://54.160.244.203:5000/stats')
            .then(response => response.json())
            .then(data => {
                const updatedCreators = creators.map(creator => {
                    const fetchedData = data.find(item => item.channel_name === creator.channelName);
                    return {
                        ...creator,
                        subscribers: fetchedData ? parseInt(fetchedData.subscribers).toLocaleString() : "N/A",
                        views: fetchedData ? parseInt(fetchedData.views).toLocaleString() : "N/A",
                        videos: fetchedData ? fetchedData.videos : "N/A",
                        activeYears: fetchedData ? fetchedData.active_for : "N/A",
                    };
                });
                setCreators(updatedCreators);
            })
            .catch(error => console.error('Error fetching data:', error));
    }, []);

    return (
        <div className="marketplace-section">
            <h1 className="marketplace-title">Marketplace</h1>
            <div className="creator-profile-container">
                {creators.map((creator, index) => (
                    <Link key={index} to={`/creators/${creator.creatorID}`} className="creator-profile-link">
                        <CreatorProfile {...creator} channelName="???" />
                    </Link>
                ))}
            </div>
        </div>
    );
};

export default Marketplace;