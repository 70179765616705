import React, { useState } from 'react';
import './JoinUs.css'; // Ensure CSS is linked for styling

function JoinUs() {
    const [formData, setFormData] = useState({
        fullName: '',
        email: '',
        message: ''
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const apiURL = 'https://778lxqg1v3.execute-api.us-east-1.amazonaws.com/dev/submit'; // Replace with your actual API Gateway URL
        try {
            const response = await fetch(apiURL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData)
            });
            if (response.ok) {
                const result = await response.json(); // Assuming server sends back JSON response
                alert('Form submitted successfully! We will get back to you soon.');
                console.log(result); // Logging the response from the server
            } else {
                const errorResponse = await response.json();
                throw new Error(`Failed to submit form: ${errorResponse.message || 'Unknown error'}`);
            }
        } catch (error) {
            console.error('Submission error:', error);
            alert(`Error submitting form: ${error.message}`);
        }
    };

    return (
        <div className="join-us-page"> {/* This is the outer container with the red background */}
            <div className="join-us-container">
                <h1>Join Us</h1>
                <p>Enter your details and we'll get back to you.</p>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="fullName">Full Name</label>
                        <input
                            type="text"
                            id="fullName"
                            name="fullName"
                            value={formData.fullName}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">Email Address</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="message">Message</label>
                        <textarea
                            id="message"
                            name="message"
                            value={formData.message}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <button type="submit" className="submit-button">Submit</button>
                </form>
            </div>
        </div>
    );
}

export default JoinUs;
