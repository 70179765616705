import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css'; // Make sure your CSS path is correct

function Header() {
  return (
    <div className="header">
      <div className="nav">
        <Link to="/" className="nav-button">Home</Link>   
        <Link to="/about" className="nav-button">About</Link> 
        <Link to="/join-us" className="nav-button">Join us</Link> 
        <Link to="/faq" className="nav-button">FAQ</Link>  
      </div>
      <div className="login">
        <button className="header-signup-button">Sign up</button>
        <button className="header-login-button">Log in</button>
      </div>
    </div>
  );
}

export default Header;
