import React from 'react';
import ViewsPerVideoGraph from './ViewsPerVideoGraph';
import CommentsPerVideoGraph from './CommentsPerVideoGraph';
import LikesPerVideoGraph from './LikesPerVideoGraph';
import VideosPerMonthGraph from './VideosPerMonthGraph';
import './Graphs.css';  // Assuming you have a CSS file for styling

const Graphs = ({ channelName }) => {
    return (
        <div className="graphs-container">
            <div className="graph">
                <ViewsPerVideoGraph channelName={channelName} />
            </div>
            <div className="graph">
                <CommentsPerVideoGraph channelName={channelName} />
            </div>
            <div className="graph">
                <LikesPerVideoGraph channelName={channelName} />
            </div>
            <div className="graph">
                <VideosPerMonthGraph channelName={channelName} />
            </div>
        </div>
    );
};

export default Graphs;
