import React from 'react';
import './About.css'; // Make sure you have the accompanying CSS file for styling

function About() {
    return (
        <div className="about-page">
            <div className="about-section left-side">
                <h1>Vidvest For Investors</h1>
                <p>Vidvest is a unique platform that empowers you to invest in the next generation of YouTube stars. As an investor on Vidvest, you have an exceptional opportunity to support talented content creators and share in their success.</p>
                <h2>How It Works:</h2>
                <ol className="list-style">
                    <li>Choose Your Creators: Browse our selection of promising YouTubers and select the ones you believe have the potential to thrive.</li>
                    <li>Invest in Their Future: Purchase a portion of their future YouTube AdSense revenue. Your investment provides them with crucial funding to grow their channels.</li>
                    <li>Earn with Them: When the creators you've invested in generate income, you earn a percentage of their YouTube earnings.</li>
                </ol>
                <h2>Benefits for Investors:</h2>
                <ol className="list-style">
                    <li>Diversify Your Portfolio: Invest in a variety of content creators to spread your risk and maximize potential returns.</li>
                    <li>Receive Monthly Reports: Stay informed about the progress of the channels you've invested in with detailed monthly reports.</li>
                    <li>Support Emerging Talent: Help budding YouTubers reach their full potential while earning a passive income.</li>
                </ol>
            </div>
            <div className="about-section right-side">
                <h1>Vidvest For Creators</h1>
                <p>Are you a passionate YouTuber looking to take your channel to the next level? Vidvest is here to help you achieve your goals by connecting you with potential investors who believe in your talent.</p>
                <h2>How It Works:</h2>
                <ol className="list-style">
                    <li>Determine Your Offering: Decide what percentage of your future YouTube AdSense revenue you'd like to sell to investors on Vidvest.</li>
                    <li>Receive Upfront Funding: Investors purchase a share of your future earnings, providing you with an immediate financial boost.</li>
                    <li>Invest in Your Channel: Use the funding to enhance your content, upgrade equipment, or focus more on your creative pursuits.</li>
                </ol>
                <h2>Benefits for Creators:</h2>
                <ol className="list-style">
                    <li>Financial Support: Access upfront funds to invest in your channel's growth and creative endeavors.</li>
                    <li>Exposure: Every investor becomes a dedicated fan, contributing to your channel's growth and popularity.</li>
                    <li>Keep Control: You retain control of your content and ownership while benefiting from investor support.</li>
                </ol>
            </div>
        </div>
    );
}

export default About;
