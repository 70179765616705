import React, { useState } from 'react';
import './Faq.css';

function Faq() {
    const [activeTab, setActiveTab] = useState('investors');
    const [activeQuestion, setActiveQuestion] = useState(null);

    const toggleQuestion = (question) => {
        setActiveQuestion(activeQuestion === question ? null : question);
    };

    const investorFAQs = [
        {
            question: "What Exactly Am I Purchasing on Enty?",
            answer: "You're purchasing a portion of a YouTuber's AdSense revenue, which is generated by ads displayed in their videos."
        },
        {
            question: "How are the Earnings from YouTube AdSense Determined?",
            answer: "The income YouTubers generate from YouTube AdSense is influenced by various factors, primarily their content niche and target audience. For instance, a YouTuber focusing on finance typically earns a higher RPM (Revenue Per Mille - revenue per 1,000 views) compared to someone in the entertainment niche. Additionally, English-speaking YouTubers tend to have a higher RPM than those who create content in other languages. On average, English-speaking creators can expect an RPM of approximately $4.5. When a YouTuber accumulates millions of views, this can result in substantial earnings."
        },
        {
            question: "Why Should I Be an Investor?",
            answer: "Have you ever liked a creator before they went viral and proudly told everyone you were their earliest supporter? Now, you can profit from it. Enty gives you the opportunity to invest in promising YouTubers. By investing you'll own a monthly portion of their YouTube AdSense revenue, providing you with a passive income stream. In addition, you'll receive a monthly report on the channel's progress, allowing you to track your investment and celebrate its success."
        },
        {
            question: "What Do The Investors Get?",
            answer: "Investors receive a share of the Creator's revenue generated through YouTube AdSense, with the specific percentage detailed on the product page. Additionally, investors gain access to monthly progress reports detailing the channel's growth."
        },
        {
            question: "How Should I Choose in Which Creator to Invest?",
            answer: "When deciding which Creator to invest in, prioritize those you believe demonstrate the potential for growth and quality content. Evaluate whether the Creator effectively engages with their audience and maintains a consistent uploading schedule. Look for signs of a dedicated and expanding fan base. Selecting Creators with these attributes can enhance your chances of a successful investment."
        },
        {
            question: "When Are Payments Made to Investors and Through Which Payment Method?",
            answer: "Investor's earnings are disbursed on the 10th of the second month following the earnings. For example, earnings generated in August will be transferred to investors on the 10th of October. The money will be transferred using PayPal."
        },
        {
            question: "Is There a Minimum or Maximum Investment Amount?",
            answer: "The minimum investment amount differs from one YouTuber to another, typically around $5. There is no maximum amount."
        },
        {
            question: "What Happens If a Creator's Channel Doesn't Generate Ad Revenue?",
            answer: "If a creator's channel doesn't earn revenue in a given month, there won't be a distribution of funds to investors for that period."
        }
    ];

    const creatorFAQs = [
        {
            question: "Why Should I Be a Creator?",
            answer: "Starting as a YouTuber can be tough, but Enty is here to support you. We, through the help of the Investors, provide upfront funding, giving you the financial freedom to invest in your channel's growth. Whether it's upgrading equipment, enhancing your content, or having more time to focus on your passion, Enty helps you take your channel to the next level. What's more, you have the flexibility to choose the percentage of your future earnings you'd like to sell to Investors. With each Investor, you gain not only financial support but also exposure and the potential for substantial growth for your channel."
        },
        {
            question: "What Do The Creators Get?",
            answer: "Creators receive upfront funding, providing them with financial resources to invest in their channels, whether it's upgrading equipment, improving content quality, or focusing more on their creative pursuits. Additionally, Creators gain exposure on the platform, as every Investor becomes a dedicated fan, contributing to their channel's growth and popularity."
        },
        {
            question: "What Type of YouTubers Are Suitable for Enty?",
            answer: "Enty is dedicated to assisting YouTubers in their initial stages of growth, providing support when they need it the most. To become a Creator on Enty, you should have achieved monetization, have fewer than 100K subscribers, and be over 18 years of age."
        },
        {
            question: "What Percentage of My YouTube Revenue Should I Sell?",
            answer: "You can choose to sell anywhere from 10% to 40% of your future YouTube AdSense revenue. We'll work with you to determine the most profitable percentage for both you and the Investors."
        },
        {
            question: "Can I Sell a Portion of My Revenue from Other Income Sources, Such as Sponsorships or Merchandise?",
            answer: "No, Enty focuses exclusively on YouTube AdSense revenue. Other income streams, like sponsorships and merchandise sales, remain entirely yours."
        },
        {
            question: "When Will Enty Transfer Funds from Investors to Creators?",
            answer: "Enty will conduct monthly transfers, providing Creators with the money contributed by Investors for the portion bought two months prior, on the 15th of each month. For example, the earnings from the portion that Investors bought in September will be transferred to the Creator on November 15th."
        }
    ];

    return (
        <div className="faq-page">
            <div className="faq-container">
                <h1>Frequently Asked Questions</h1>
                <div className="tabs">
                    <button
                        className={`tab ${activeTab === 'investors' ? 'active' : ''}`}
                        onClick={() => setActiveTab('investors')}
                    >
                        Investors
                    </button>
                    <button
                        className={`tab ${activeTab === 'creators' ? 'active' : ''}`}
                        onClick={() => setActiveTab('creators')}
                    >
                        Creators
                    </button>
                </div>
                <div className="questions">
                    {activeTab === 'investors' && investorFAQs.map((faq, index) => (
                        <div key={index} className="question-item" onClick={() => toggleQuestion(`investor-${index}`)}>
                            <div className="question">{faq.question}</div>
                            {activeQuestion === `investor-${index}` && (
                                <div className="answer">{faq.answer}</div>
                            )}
                        </div>
                    ))}
                    {activeTab === 'creators' && creatorFAQs.map((faq, index) => (
                        <div key={index} className="question-item" onClick={() => toggleQuestion(`creator-${index}`)}>
                            <div className="question">{faq.question}</div>
                            {activeQuestion === `creator-${index}` && (
                                <div className="answer">{faq.answer}</div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Faq;
