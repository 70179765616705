import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Header from './Header'; // Ensure the Header component path is correct
import FeatureSection from './FeatureSection';
import Marketplace from './Marketplace'; // Import the Marketplace component
import About from './About'; // Import the About component
import JoinUs from './JoinUs'; // Import the JoinUs component
import Faq from './Faq';
import CreatorPage from './CreatorPage'; // Import the CreatorPage component


function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={
            <>
              <FeatureSection />
              <Marketplace />
              {/* Include any other components that should appear on the home page */}
            </>
          } />
          <Route path="/about" element={<About />} />
          <Route path="/join-us" element={<JoinUs />} /> {/* New route for the Join Us page */}
          <Route path="/faq" element={<Faq />} />
          <Route path="/creators/:creatorID" element={<CreatorPage />} />
          {/* Add other routes as needed */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
