import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale, Filler } from 'chart.js';
import 'chartjs-adapter-date-fns';
import { format } from 'date-fns';
import './ViewsPerVideoGraph.css';

// Register necessary Chart.js components along with the Filler plugin
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale, Filler);

const VideosPerMonthGraph = ({ channelName }) => {
    const [videoData, setVideoData] = useState([]);

    useEffect(() => {
        fetch(`https://lzln18r7gj.execute-api.us-east-1.amazonaws.com/dev/fetch-graph-data`)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                const filteredData = data.filter(video => video.channel_name === channelName);
                const groupedData = {};
                filteredData.forEach(video => {
                    const monthYear = format(new Date(video.date), 'yyyy-MM');
                    if (!groupedData[monthYear]) {
                        groupedData[monthYear] = { count: 0 };
                    }
                    groupedData[monthYear].count += 1;  // Increment count for each video in the month
                });
                const formattedData = Object.keys(groupedData).map(monthYear => {
                    return {
                        date: new Date(monthYear + '-01'),
                        count: groupedData[monthYear].count
                    };
                });
                setVideoData(formattedData.sort((a, b) => a.date - b.date));
            })
            .catch(error => {
                console.error('Error fetching video data:', error);
            });
    }, [channelName]);

    const data = {
        datasets: [{
            label: 'Videos Uploaded',
            data: videoData.map(video => ({
                x: video.date,
                y: video.count
            })),
            borderColor: '#eb455f',
            backgroundColor: function(context) {
                const chart = context.chart;
                const {ctx, chartArea} = chart;

                if (!chartArea) {
                    return;
                }
                const gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
                gradient.addColorStop(0, 'rgba(235, 69, 95, 0)');
                gradient.addColorStop(0.3, 'rgba(235, 69, 95, 0.5)');
                return gradient;
            },
            fill: true,
            tension: 0.3
        }]
    };

    const options = {
    scales: {
        x: {
            type: 'time',
            time: {
                unit: 'month',
                displayFormats: {
                    month: 'MMM yyyy'
                },
                tooltipFormat: 'MMMM yyyy'
            },
            ticks: {
                padding: 0,
                color: '#86878f',
                callback: function(value, index, ticks) {
                    const date = new Date(ticks[index].value);
                    if (index % 6 === 0) {
                        return format(date, 'MMM yy');
                    }
                    return '';
                }
            },
            grid: {
                color: '#293045'
            }
        },
        y: {
            type: 'linear',
            beginAtZero: true,
            ticks: {
                padding: 0,
                color: '#86878f'
            },
            grid: {
                color: '#293045'
            }
        }
    },
    layout: {
        padding: {
            bottom: 0,  // Reduce or remove padding at the bottom
            top: 0
        }
    },
    responsive: true,
    plugins: {
        legend: {
            display: false
        },
        tooltip: {
            enabled: true,
            mode: 'index',
            intersect: false,
            displayColors: false
        }
    },
};

    return (
        <div className="chart-container" style={{backgroundColor: "#27293c", color: "#707179", padding: "20px", borderRadius: "8px"}}>
        <h2 className="chart-title">Videos Uploaded</h2>
            <Line data={data} options={options} />
        </div>
    );
};

export default VideosPerMonthGraph;
