import React from 'react';
import './FeatureSection.css'; // CSS file for styling

function FeatureSection() {
  return (
    <div className="feature-section">
      <img src="/vidvest-logo2.png" alt="vidviest Logo" className="enty-logo" />
      <img src="/Computer-image.jpeg" alt="Computer" className="computer-image" />
      <div className="slogen-container">
        <p className="slogen">Creators grow,<br/> Investors profit.</p>

      </div>
    </div>
  );
}

export default FeatureSection;
