import React from 'react';
import './CreatorProfileBox.css'; // Ensure CSS is in a separate file

const CreatorProfileBox = ({ imageUrl, channelName, category, subscribers, views, videos, activeYears, rpm, price, percentage }) => {
    return (
        <div className="creator-box-profile">
            <img src={imageUrl} alt="Creator" className="creator-box-image"/>
            <div className="info">
                <p className="creator-name"><strong>{channelName}</strong></p>
                <p className="details">Category: {category}</p>
                <p className="details">Subscribers: {subscribers}</p>
                <p className="details">Views: {views}</p>
                <p className="price-info"><strong>{`$${price} for ${percentage}%`}</strong></p>
            </div>
        </div>
    );
}

export default CreatorProfileBox;
